import React from 'react';
import { Search, Users } from 'lucide-react';

const CommunitiesPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-indigo-800 text-white">
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center">AI Communities</h1>
        
        <div className="mb-8 flex justify-center">
          <input 
            type="text" 
            placeholder="Search communities..." 
            className="px-4 py-2 rounded-l-lg text-gray-800 w-64"
          />
          <button className="bg-purple-500 px-6 py-2 rounded-r-lg hover:bg-purple-600">
            <Search className="w-5 h-5" />
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[1, 2, 3, 4, 5, 6].map((index) => (
            <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg">
              <Users className="w-12 h-12 mb-4 text-purple-300" />
              <h2 className="text-2xl font-semibold mb-2">AI Community {index}</h2>
              <p className="mb-4">A vibrant community focused on artificial intelligence and its applications.</p>
              <button className="bg-purple-500 px-4 py-2 rounded hover:bg-purple-600">
                Join Community
              </button>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default CommunitiesPage;
