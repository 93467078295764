import React from 'react';
import { Calendar, MapPin, Clock } from 'lucide-react';

const EventsPage: React.FC = () => {
  const events = [
    {
      title: "The AI Impact Tour: How to Scale Enterprise AI in the Era of LLMs",
      date: "August 7th, 2024",
      time: "9:30 AM - 12:00 PM",
      location: "Boston",
      description: "This salon event tackles the challenges enterprises face in scaling AI projects from pilot phases to full-scale implementations. It emphasizes the importance of orchestrating sophisticated generative AI for success and covers tips, best practices, and technologies to facilitate this scale. Note that event details may change, so check the website for the latest information.",
      link: "https://impact.venturebeat.com/ai-impact-tour/how-to-scale-enterprise-ai-in-the-era-of-llms/",
    },
    {
      title: "Ai4 2024",
      date: "Mon Aug 12, 2024 - Wed Aug 14, 2024",
      time: "1:00 PM - 11:00 PM",
      location: "MGM Grand, 89109",
      description: "Ai4 2024 is the world’s largest gathering of artificial intelligence leaders in business. Join thousands of executives and technology innovators at the epicenter of the AI community. The conference will welcome 4000+ attendees, 200+ cutting edge AI exhibitors, and 300+ speakers from leading organizations.",
      link: "https://tickets.foragroup.io/events/foragroup/1189113/r/uniteai",
    },
    {
      title: "THE AI CONFERENCE 2024",
      date: "Sept 10th and 11th, 2024",
      location: "San Francisco",
      description: "Shaping the Future of AI.",
      link: "https://aiconference.com/",
    },
    {
      title: "3rd Annual Industrial AI Conference",
      date: "Sept 5-6, 2024",
      location: "Silicon Valley",
      description: "Start-ups, Investors, and Engineers: Don’t Miss This Rare Opportunity to Meet the Leaders of Industrial AI. Dive deep into human & machine intelligence in the $25T industrial economy. Engineer the breakthroughs that will redefine industry. Unearth Industrial AI’s next big thing.",
      link: "https://www.k1st.world/",
    },
    {
      title: "AI Hardware & Edge Summit",
      date: "September 9 to 12, 2024",
      location: "San Jose, CA",
      description: "The AI Hardware & Edge AI Summit is the premier event for AI and ML professionals, focusing on building, training, and deploying fast, affordable, and efficient machine learning systems. Network with over 1,200 industry peers, stay updated on the semiconductor landscape, and learn from top AI experts.",
      link: "https://aihwedgesummit.com/events/aihwedgesummit",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-indigo-800 text-white">
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center">Upcoming AI Events</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {events.map((event, index) => (
            <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg">
              <Calendar className="w-12 h-12 mb-4 text-purple-300" />
              <h2 className="text-2xl font-semibold mb-2">{event.title}</h2>
              <p className="mb-4">{event.description}</p>
              <div className="flex items-center mb-2">
                <Clock className="w-5 h-5 mr-2" />
                <span>{event.date}</span>
              </div>
              {event.time && (
                <div className="flex items-center mb-2">
                  <Clock className="w-5 h-5 mr-2" />
                  <span>{event.time}</span>
                </div>
              )}
              <div className="flex items-center mb-4">
                <MapPin className="w-5 h-5 mr-2" />
                <span>{event.location}</span>
              </div>
              <a href={event.link} className="bg-purple-500 px-4 py-2 rounded hover:bg-purple-600">
                Learn More
              </a>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default EventsPage;
