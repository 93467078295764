import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './HomePage';
import CommunitiesPage from './CommunitiesPage';
import EventsPage from './EventsPage';
import AboutPage from './AboutPage';

const App: React.FC = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-br from-purple-600 to-indigo-800 text-white flex flex-col">
        <header className="container mx-auto px-4 py-6">
          <nav className="flex justify-between items-center">
            <h1 className="text-3xl font-bold">EraShift</h1>
            <ul className="flex space-x-6">
              <li><Link to="/" className="hover:text-purple-200">Home</Link></li>
              <li><Link to="/communities" className="hover:text-purple-200">Communities</Link></li>
              <li><Link to="/events" className="hover:text-purple-200">Events</Link></li>
              <li><Link to="/about" className="hover:text-purple-200">About</Link></li>
            </ul>
          </nav>
        </header>

        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/communities" element={<CommunitiesPage />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </main>

        <footer className="bg-indigo-900 py-8 mt-auto">
          <div className="container mx-auto px-4 text-center">
            <p>&copy; 2024 EraShift.org. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
};

export default App;