import React from 'react';
import { Info, Mail, Construction } from 'lucide-react';

const AboutPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <div className="bg-yellow-400 text-black p-4 rounded-lg mb-8 shadow-md">
        <div className="flex items-center justify-center mb-2">
          <Construction className="w-6 h-6 mr-2" />
          <h2 className="text-2xl font-bold">Website Under Construction</h2>
        </div>
        <p className="text-center">
          We're working hard to bring you the full EraShift experience! An upcoming update will include the complete website's features, including enhanced community discovery, event management, and more. Stay tuned for exciting changes!
        </p>
      </div>

      <h1 className="text-4xl font-bold mb-8 text-center">About EraShift</h1>
      
      <div className="bg-white bg-opacity-10 p-8 rounded-lg mb-8">
        <Info className="w-16 h-16 mb-4 text-purple-300 mx-auto" />
        <p className="text-lg mb-4">
          EraShift is a platform dedicated to connecting AI enthusiasts, professionals, and learners. 
          Our mission is to foster local AI communities and facilitate knowledge sharing in the rapidly evolving field of artificial intelligence.
        </p>
        <p className="text-lg mb-4">
          Whether you're a seasoned AI professional, a curious beginner, or somewhere in between, 
          EraShift provides the tools and connections you need to grow in your AI journey.
        </p>
      </div>

      <div className="bg-white bg-opacity-10 p-8 rounded-lg">
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <div className="flex items-center mb-4">
          <Mail className="w-6 h-6 mr-2" />
          <a href="mailto:erashiftorg@gmail.com" className="hover:text-purple-300">erashiftorg@gmail.com</a>
        </div>
        <p>
          We'd love to hear from you! Whether you have questions, suggestions, or just want to say hello, 
          don't hesitate to reach out.
        </p>
      </div>
    </div>
  );
};

export default AboutPage;