import React, { useEffect, useRef } from 'react';
import { Search, Users, Globe, Calendar } from 'lucide-react';
import VanillaTilt from 'vanilla-tilt';

const HomePage: React.FC = () => {
  const tiltRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const currentTiltRefs = tiltRefs.current;
    currentTiltRefs.forEach((element) => {
      if (element) {
        VanillaTilt.init(element, {
          max: 5,
          speed: 300,
          glare: true,
          'max-glare': 0.1,
          scale: 1.02,
        });
      }
    });

    // Clean up
    return () => {
      currentTiltRefs.forEach((element) => {
        if (element) {
          (element as any).vanillaTilt.destroy();
        }
      });
    };
  }, []);

  const events = [
    {
      title: "The AI Impact Tour: How to Scale Enterprise AI in the Era of LLMs",
      date: "August 7th, 2024",
      time: "9:30 AM - 12:00 PM",
      location: "Boston",
      link: "https://impact.venturebeat.com/ai-impact-tour/how-to-scale-enterprise-ai-in-the-era-of-llms/",
    },
    {
      title: "Ai4 2024",
      date: "Mon Aug 12, 2024 - Wed Aug 14, 2024",
      time: "1:00 PM - 11:00 PM",
      location: "MGM Grand, 89109",
      link: "https://tickets.foragroup.io/events/foragroup/1189113/r/uniteai",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <section className="text-center mb-16">
        <h2 className="text-5xl font-bold mb-4">Connect with Local AI Communities</h2>
        <p className="text-xl mb-8">Discover, network, and grow in the world of Artificial Intelligence</p>
        <div className="flex justify-center">
          <input 
            type="text" 
            placeholder="Enter your location" 
            className="px-4 py-2 rounded-l-lg text-gray-800 w-64"
          />
          <button className="bg-purple-500 px-6 py-2 rounded-r-lg hover:bg-purple-600 transition duration-300">
            Find Communities
          </button>
        </div>
      </section>

      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[
          { icon: Search, title: 'Discover', description: 'Find AI communities and events in your area.' },
          { icon: Users, title: 'Connect', description: 'Network with like-minded AI enthusiasts and professionals.' },
          { icon: Globe, title: 'Grow', description: 'Expand your knowledge and opportunities in AI.' },
        ].map((card, index) => (
          <div
            key={card.title}
            ref={(el) => (tiltRefs.current[index] = el)}
            className="bg-white bg-opacity-10 p-6 rounded-lg transition-all duration-300 hover:shadow-md"
          >
            <card.icon className="w-12 h-12 mb-4 text-purple-300" />
            <h3 className="text-2xl font-semibold mb-2">{card.title}</h3>
            <p>{card.description}</p>
          </div>
        ))}
      </section>

      <section className="mt-16">
        <h2 className="text-3xl font-bold mb-6 text-center">Upcoming AI Events</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {events.map((event, index) => (
            <div
              key={event.title}
              ref={(el) => (tiltRefs.current[index + 3] = el)}
              className="bg-white bg-opacity-10 p-6 rounded-lg transition-all duration-300 hover:shadow-md"
            >
              <Calendar className="w-8 h-8 mb-2 text-purple-300" />
              <h4 className="text-xl font-semibold mb-2">{event.title}</h4>
              <p className="mb-2">Date: {event.date}</p>
              {event.time && <p className="mb-2">Time: {event.time}</p>}
              <p className="mb-2">Location: {event.location}</p>
              <a href={event.link} className="text-purple-300 hover:text-purple-400 transition duration-300">
                Learn More
              </a>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HomePage;
